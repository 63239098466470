.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    display: block;
    width: 100%;

}

.react-datepicker{
    font-family: inherit;
    border: 0;
    border-radius: 20px;
    box-shadow: 5px -2px 20px 3px rgb(0 0 0 / 10%), 0px 0px 0px 0px rgb(0 0 0 / 10%) inset;
}


.react-datepicker-popper { 
    z-index: 100 !important;
    
}